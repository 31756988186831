import React, { useState } from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/authContext';
import logo from '../assets/logo3.png'

export default function Navbarcomp() {
  const history = useNavigate()
  const [error, setError] = useState('')
  const { logout } = useAuth();

  async function handleLogout(){
    setError('')
    try{
        await logout()
        history('/login')
    } catch{
        setError('Failed to log out')
    }
}


  return (
    <Navbar bg="dark" variant="dark">
    <Container>
    
    <Navbar.Brand href="/"><img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}AIQUANTUM SS</Navbar.Brand>
    <Nav className="justify-content-end">
      <Nav.Link href="/upload">Upload Video</Nav.Link>
      <Nav.Link href="/update-profile">Update Profile</Nav.Link>
      <Nav.Link href="#" onClick={handleLogout}>Log Out</Nav.Link>
    </Nav>
    </Container>
  </Navbar>
  )
}
