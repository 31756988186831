import React, {useRef,useState} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap' 
import { useAuth } from '../contexts/authContext';
import { Link} from 'react-router-dom'

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  async function handleSubmit(e){
      e.preventDefault();
      

      try{
          setMessage('')
          setError('')
          setLoading(true)
          await resetPassword(emailRef.current.value)
          setMessage('Check your email to reset your password')
      } catch {
          setError('Failed to reset password')
      }
      setLoading(false)
      
  }

  return (
    <>
        <Card>
            <Card.Body>
                <h2 className="text-center mb-3">Password Reset</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" required ref={emailRef}></Form.Control>
                    </Form.Group>
                    <Button disabled={loading} type="submit" className="w-100 mt-3">Reset Password</Button>
                </Form>
                <div className="w-100 text-center mt-3">
                    <Link to="/login">Login</Link>
                </div>
            </Card.Body>

        </Card>
        
    </>
  )
}
