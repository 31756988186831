import { Container } from "react-bootstrap";
import { AuthProvider } from "../contexts/authContext";
import SignUp from "./SignUp";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Dashboard from "./Dashboard";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
import UploadForm from "./UploadForm";
import Navbarcomp from "./Navbarcomp";
import Detail from "./Detail";
import Landing from "./Landing";
import Gaiic from "./Gaiic";
import Events from "./Events";
import CourseInstructors from "./CourseInstructors";
import Resources from "./Resouces";


function App() {
  return (
    
      
        <Router>
          <AuthProvider>
          
        {/* <Navbarcomp/> */}
            <Routes>
            <Route path="/"

                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                  ></Route>
                  <Route path="/update-profile"

                      element={
                        <PrivateRoute>
                          <UpdateProfile />
                        </PrivateRoute>
                      }
                      ></Route>
                  <Route path="/upload" element={
                        <PrivateRoute>
                          <UploadForm />
                        </PrivateRoute>
                      }></Route>
                  <Route path="/videos/:id" element={
                        <PrivateRoute>
                          <Detail />
                        </PrivateRoute>
                      }></Route>
              <Route path="/signup" element={<SignUp/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/forgot-password" element={<ForgotPassword/>} />
              <Route path="/home" element={<Landing/>} />
              <Route path="/gaiic" element={<Gaiic/>} />
              <Route path="/event" element={<Events/>} />
              <Route path="/course-instructors" element={<CourseInstructors/>} />
              <Route path="/resources" element = {<Resources/>} />
            </Routes>
          </AuthProvider>
        </Router>
        
      
    
    
    
  );
}

export default App;
