import React from 'react'


import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from '../containers';

import { CTA, Brand, Navbar } from './';

import './loading.css';

import Carous from './Carous';

export default function Landing() {
  return (
    <>
      <div className='gradient__bg'>
        <Navbar />
        <Header />
      </div>
      <div style={{backgroundColor:'#ffff', marginTop:'30px',  paddingBottom:'30px'}}><WhatGPT3 /></div>
      
      <Features />
      <Possibility />
      <div style={{backgroundColor:'#ffff'}}>
      <CTA />
      </div>
      
      <Blog />
      <Carous />
      <Footer />
    </>
    
  )
}
