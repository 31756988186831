import React, {useRef,useState} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap' 
import { useAuth } from '../contexts/authContext';
import {Link, useNavigate} from 'react-router-dom'
import Navbarcomp from './Navbarcomp';
import Navbaruser from './Navbaruser';

export default function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useNavigate()

   function handleSubmit(e){
      e.preventDefault();
      if(passwordRef.current.value!==passwordConfirmRef.current.value){
        return setError('Passwords do not match')
      }

      const promises = []
      setLoading(true)
      setError('')
      if(emailRef.current.value!==currentUser.email){
          promises.push(updateEmail(emailRef.current.value))
      }

      if(passwordRef.current.value){
            promises.push(updatePassword(passwordRef.current.value)) 
      }

      Promise.all(promises).then(() => {
          history('/')
      }).catch(() => {
          setError('Failed to update account')
      }).finally(() => {
          setLoading(false)
      })
      
      
  }

  if(currentUser.email==='admin@admin.com') {return (
    <>
        <Navbarcomp />
        <Card>
            <Card.Body>
                <h2 className="text-center mb-3">Update Profile</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" required ref={emailRef} defaultValue={currentUser.email}></Form.Control>
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password"  ref={passwordRef} placeholder="Leave blank to keep the same"></Form.Control>
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control type="password"  ref={passwordConfirmRef} placeholder="Leave blank to keep the same"></Form.Control>
                    </Form.Group>
                    <Button disabled={loading} type="submit" className="w-100 mt-3">Update</Button>
                </Form>
            </Card.Body>

        </Card>
        
    </>

  )
} else {
    return (<>
        <Navbaruser />
        <Card>
            <Card.Body>
                <h2 className="text-center mb-3">Update Profile</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" required ref={emailRef} defaultValue={currentUser.email}></Form.Control>
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password"  ref={passwordRef} placeholder="Leave blank to keep the same"></Form.Control>
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control type="password"  ref={passwordConfirmRef} placeholder="Leave blank to keep the same"></Form.Control>
                    </Form.Group>
                    <Button disabled={loading} type="submit" className="w-100 mt-3">Update</Button>
                </Form>
            </Card.Body>

        </Card>
        </>)
}
}
