import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore'

const app = firebase.initializeApp({
    apiKey: "AIzaSyCiY6QQwIFlzCpltdCFx4IKVmWFgWJw-so",
    authDomain: "auth-development-c5b9d.firebaseapp.com",
    projectId: "auth-development-c5b9d",
    storageBucket: "auth-development-c5b9d.appspot.com",
    messagingSenderId: "938733378070",
    appId: "1:938733378070:web:7e35ab62ae29372b09f337",
    measurementId: "G-HKEM3SP9YC"
})

export const projectStorage = firebase.storage()
export const projectFirestore = firebase.firestore()
export const auth = app.auth()
export const timestamp = firebase.firestore.FieldValue.serverTimestamp
export default app