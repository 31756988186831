import React from 'react'
import { Feature } from '../../components'
import './whatGPT3.css'

export default function WhatGPT3() {
  return (
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3" style={{backgroundColor:'#ffff'}}>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">About Us</h1>
      </div>
      
      <div className="gpt3__whatgpt3-feature">
      <p style={{color:'white',fontSize:'17px'}}>
      {/* AIQuantum Smart Solutions Pvt. Ltd company is incorporated on 21st April 2022 (CIN No: U72900KA2022PTC160286) under Companies act 2013 (18 of 2013), Ministry of Corporate Affairs (MCA), Government of India and it is registered in Karnataka State.  The company is established by the Vision of eminent researchers Dr. Shyam Lal, Dr. Sandeep Kumar, Dr. Sushil Kumar Pandey, Faculty members of National Institute of Technology Karnataka (NITK), Surathkal, Karnataka, India. The company is established by the Vision of eminent researchers Dr. Shyam Lal, Dr. Sandeep Kumar, Dr. Sushil Kumar Pandey, Faculty members of  National Institute of Technology Karnataka (NITK) Surathkal, Karnataka, India */}
      <ul>
        <li>AIQuantum Smart Solutions Private Limited company was incorporated on 21st April 2022 under Companies act 2013 (18 of 2013), Ministry of Corporate Affairs, Government of India and it is registered in Karnataka State.</li>
        <li>The company is also recognized as a startup for 10 years by the Department for Promotion of Industry and Internal (DIPP) Trade, Ministry of Corporate Affairs, Government of India</li>
        <li>The company is established by the vision of eminent researchers Dr. Shyam Lal, Dr. Sandeep Kumar, Dr. Sushil Kumar Pandey, Faculty members of National Institute of Technology Karnataka (NITK), Surathkal, Karnataka, India.</li>
      </ul>
 </p>
      
    </div>
    </div>
  )
}
