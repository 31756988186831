import React from 'react'
import './footer.css';

export default function Footer() {
  return (
    <div className="gpt3__footer section__padding" id="footer">
      

      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          {/* <img src={gpt3Logo} alt="gpt3_logo" /> */}
          <h4 style={{color:'#ffff'}}>AIQUANTUM Smart Solutions Private Limited</h4><br /><p> All Rights Reserved</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Corporate  Address</h4>
          <p>Unit-14, Science  & Technology Entrepreneur’s Park (STEP),  National Institute of Technology Karnataka (NITK), Surathkal, Srinivasnagar PO, Mangaluru-575025, Dakshina Kannada, Karnataka, India </p>
          <br/>
          <h4>Postal Address</h4>
          <p>P-20, NITK Campus, Surathkal, Srinivasnagar PO, Mangaluru-575025, Dakshina Kannada, Karnataka, India</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Get in touch</h4>
          <p>Contact Tel.:<br/> +91-9741072082, +91-9654463102
</p>
          <p> Support E-mail:<br/><br/>support@aiquantumsmartsolutions.com<br/> aiquantumss@gmail.com</p>
          <p> E-mail:<br/><br/> aiquantumsmartsolutions@gmail.com<br/>director@aiquantumsmartsolutions.com</p>
        </div>
      </div>

      <div className="gpt3__footer-copyright">
        <p>@2022 AIQUANTUM Smart Solutions Private Limited. All rights reserved.</p>
      </div>
    </div>
  )
}
