import React, {useRef,useState} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap' 
import { useAuth } from '../contexts/authContext';
import {useNavigate} from 'react-router-dom'

export default function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useNavigate()

  async function handleSubmit(e){
      e.preventDefault();
      if(passwordRef.current.value!==passwordConfirmRef.current.value){
        return setError('Passwords do not match')
      }

      try{
          setError('')
          setLoading(true)
          await signup(emailRef.current.value, passwordRef.current.value)
          history('/')
      } catch {
          setError('Failed to create account')
      }
      setLoading(false)
      
  }

  return (
    <>
        <Card>
            <Card.Body>
                <h2 className="text-center mb-3">Sign Up</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" required ref={emailRef}></Form.Control>
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" required ref={passwordRef}></Form.Control>
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control type="password" required ref={passwordConfirmRef}></Form.Control>
                    </Form.Group>
                    <Button disabled={loading} type="submit" className="w-100 mt-3">Sign Up</Button>
                </Form>
            </Card.Body>

        </Card>
        <div className='w-100 text-center mt-2'>
            Already have an account? Log In
        </div>
    </>
  )
}
