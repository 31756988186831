import React, {useState} from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import logo from '../../assets/logo3.png'

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
        <a href="/home" style={{textDecoration:'None',color:'#ffff'}}><img src={logo} /></a>
        </div>
        <div className="gpt3__navbar-links_container">
          <p><a href="/home#wgpt3" style={{textDecoration:'None',color:'#ffff'}}>About</a></p>
          <p><a href="/home#blog" style={{textDecoration:'None',color:'#ffff'}}>Team</a></p>
          <p><a href="/home#features" style={{textDecoration:'None',color:'#ffff'}}>Services</a></p>
          <p><a href="/home#research" style={{textDecoration:'None',color:'#ffff'}}>R & D areas</a></p>
          <p><a href="/home#cooperation" style={{textDecoration:'None',color:'#ffff'}}>Cooperation Horizon</a></p>
          <p><a href="/home#carous" style={{textDecoration:'None',color:'#ffff'}}>Gallery</a></p>
          <p><a href="/gaiic" style={{textDecoration:'None',color:'#ffff'}}>Global-AIIC</a></p>
          <p><a href="/event" style={{textDecoration:'None',color:'#ffff'}}>Events</a></p>
          <p><a href="/resources" style={{textDecoration:'None',color:'#ffff'}}>Resources</a></p>
          <p><a href="/home#footer" style={{textDecoration:'None',color:'#ffff'}}>Contact Us</a></p>
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <button type="button"><a href="/login" style={{textDecoration:'None',color:'#ffff'}}>Sign In</a></button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
          <p><a href="#wgpt3" style={{textDecoration:'None',color:'#ffff'}}>About</a></p>
          <p><a href="#blog" style={{textDecoration:'None',color:'#ffff'}}>Team</a></p>
          <p><a href="#features" style={{textDecoration:'None',color:'#ffff'}}>Services</a></p>
          <p><a href="#research" style={{textDecoration:'None',color:'#ffff'}}>R & D areas</a></p>
          <p><a href="#cooperation" style={{textDecoration:'None',color:'#ffff'}}>Cooperation Horizon</a></p>
          <p><a href="/gaiic" style={{textDecoration:'None',color:'#ffff'}}>Global-AIIC</a></p>
          <p><a href="/event" style={{textDecoration:'None',color:'#ffff'}}>Events</a></p>
          <p><a href="#carous" style={{textDecoration:'None',color:'#ffff'}}>Gallery</a></p>
          <p><a href="/gaiic" style={{textDecoration:'None',color:'#ffff'}}>Global-AIIC</a></p>
          <p><a href="/event" style={{textDecoration:'None',color:'#ffff'}}>Events</a></p>
          <p><a href="/resources" style={{textDecoration:'None',color:'#ffff'}}>Resources</a></p>
          <p><a href="#footer" style={{textDecoration:'None',color:'#ffff'}}>Contact Us</a></p>
          </div>
          <div className="gpt3__navbar-menu_container-links-sign">
            <button type="button"><a href="/login" style={{textDecoration:'None',color:'#ffff'}}>Sign In</a></button>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
