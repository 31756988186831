import React from 'react'
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Training',
    text: 'Conducting Training program for Undergraduate and Postgraduate students of engineering, science and technology with a balanced exposure of fundamentals and hands on experimental validation.',
  },
  {
    title: 'Research and consultancy ',
    text: 'Offering Research and consultancy  services based on Artificial Intelligence (AI), Machine Learning (ML) and Deep Learning (DL) Algorithms/Architectures in the domains of Healthcare, Earth Observation (EO) data, Climate change,  Internet of Things (IoT), 5G and B5G Networks, AR/VR, Cybersecurity, Robotics, Manufacturing, Nano Technology, Radio Frequency (RF), Chip Design and Fabrication, and other emerging applications.',
  },
  {
    title: 'Product Design',
    text: 'Specialized Product Design & Development of Emerging Technologies and Chip Fabrication.',
  },
];

export default function Features() {
  return (
    <div className="gpt3__features section__padding" id="features">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text"> Core Services</h1>
      </div>
      <div className="gpt3__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}
