import React from 'react'
import { Feature } from '../components'
import { Footer } from '../containers'
import Navbar from './navbar/Navbar'

export default function Resources() {
  return (
      <div className="gradient__bg">
          <Navbar />
          <div className="gpt3__whatgpt3-heading">
                <h1 style={{color:'#ffff', paddingLeft:'15px', maxWidth:'1000px'}}>LINK OF LEARNING MATERIALS FOR PROJECT WORK:</h1>
          </div>
          <div className="gpt3__whatgpt3-heading">
                <h3 style={{color:'#ffff', paddingLeft:'15px', maxWidth:'1000px'}}>Link for LaTeX tutorials for preparing manuscript & project report:</h3>
          </div>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          <a href='https://www.overleaf.com/learn/latex/LaTeX_video_tutorial_for_beginners_(video_1)' style={{textDecoration:'none', color:'#ffff'}}>https://www.overleaf.com/learn/latex/LaTeX_video_tutorial_for_beginners_(video_1) </a><br></br></p>
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          <a href='https://www.overleaf.com/learn/latex/Learn_LaTeX_in_30_minutes' style={{textDecoration:'none', color:'#ffff'}}>https://www.overleaf.com/learn/latex/Learn_LaTeX_in_30_minutes </a><br></br></p>
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          <a href='https://www.overleaf.com/learn/latex/Tutorials' style={{textDecoration:'none', color:'#ffff'}}>https://www.overleaf.com/learn/latex/Tutorials </a><br></br></p>
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          <a href='https://libguides.mines.edu/LaTeX/OverLeaf' style={{textDecoration:'none', color:'#ffff'}}>https://libguides.mines.edu/LaTeX/OverLeaf </a><br></br></p>
          <div className="gpt3__whatgpt3-heading">
                <h3 style={{color:'#ffff', paddingLeft:'15px', maxWidth:'1100px'}}>Link for Convolutional Neural Networks (Stanford University CS231 Lect. 1 to Lect. 9):</h3>
          </div>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          <a href='https://www.youtube.com/playlist?list=PLC1qU-LWwrF64f4QKQT-Vg5Wr4qEE1Zxk' style={{textDecoration:'none', color:'#ffff'}}>https://www.youtube.com/playlist?list=PLC1qU-LWwrF64f4QKQT-Vg5Wr4qEE1Zxk </a><br></br></p>
          <div className="gpt3__whatgpt3-heading">
                <h3 style={{color:'#ffff', paddingLeft:'15px', maxWidth:'1100px'}}>
Link for Recurrent Neural Networks ((Stanford University CS231 Lect. 10):</h3>
          </div>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          <a href='https://www.youtube.com/watch?v=6niqTuYFZLQ&list=PLC1qU-LWwrF64f4QKQT-Vg5Wr4qEE1Zxk&index=10' style={{textDecoration:'none', color:'#ffff'}}>https://www.youtube.com/watch?v=6niqTuYFZLQ&list=PLC1qU-LWwrF64f4QKQT-Vg5Wr4qEE1Zxk&index=10 </a><br></br></p>
          <div className="gpt3__whatgpt3-heading">
                <h3 style={{color:'#ffff', paddingLeft:'15px', maxWidth:'1100px'}}>Link for Deep Reinforcement Learning (Stanford University CS231 Lect. 14):</h3>
          </div>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          <a href='https://www.youtube.com/watch?v=lvoHnicueoE&list=PLC1qU-LWwrF64f4QKQT-Vg5Wr4qEE1Zxk&index=14' style={{textDecoration:'none', color:'#ffff'}}>https://www.youtube.com/watch?v=lvoHnicueoE&list=PLC1qU-LWwrF64f4QKQT-Vg5Wr4qEE1Zxk&index=14 </a><br></br></p>
          
          
          
        <Footer />
      </div>
      
  )
}
