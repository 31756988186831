import React from 'react'
import { Feature } from '../components'
import { Footer } from '../containers'
import Navbar from './navbar/Navbar'

export default function Events() {
  return (
      <div className="gradient__bg">
          <Navbar />
          <div className="gpt3__whatgpt3-heading">
                <h1 style={{color:'#ffff', paddingLeft:'15px'}}>Past Events: </h1>
          </div>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          AIQSS2022SI: 6 Weeks Virtual Summer Internship on Machine Learning and Deep Learning with Applications from 22-08-2022 to 03-10-2022<br></br>
          No. of students attended: 06</p>

          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          AIQSS2022WI: 6 Weeks Virtual Summer Internship on Machine Learning and Deep Learning with Applications from 21-11-2022 to 07-01-2023<br></br>
          No. of students attended: 08</p>
           <div className="gpt3__whatgpt3-heading">
                <h1 style={{color:'#ffff', paddingLeft:'15px'}}>Upcoming Event: </h1>
          </div>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          AIQSS2023WI: Registration Open for One Month Online Winter Research Internship on Machine Learning and Deep Learning with Applications from 22-02-2023 to 24-03-2023<br/> <br/>
          Who can attend: Only for Research scholar(Ph.D Student)  of Indian Universities and Engineering Colleges<br/><br/>
          Link for registration: <a href="https://forms.gle/B7Ju5aUN3JqpGz1j7">Click Here</a><br></br><br></br>
          Last date for Internship registration: 20th February 2023<br/><br/>
          Link for workshop flyer: <a href="https://drive.google.com/drive/folders/1kTndXMlXKdv40RDk_mIm8d5lV74oq9Cv?usp=sharing">Click here</a> <br/><br/>

(For any enquiry contact internship coordinator: aiquantumss@gmail.com (+91-9741072082)</p>
          
          
        <Footer />
      </div>
      
  )
}
