import React from 'react'
import {Carousel} from 'react-bootstrap'
import c1 from '../assets/c1.png'
import c2 from '../assets/c2.png'
import c3 from '../assets/c3.png'
import c4 from '../assets/c4.JPG'
import c5 from '../assets/c5.JPG'
import c6 from '../assets/c6.JPG'
import c7 from '../assets/c7.jpg'

export default function Carous() {
  return (
    <Carousel variant="dark" style={{marginTop:'50px'}} id="carous">
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={c1}
      alt="First slide"
    />
    
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={c2}
      alt="Second slide"
    />

   
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={c3}
      alt="Third slide"
    />

    
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={c4}
      alt="Third slide"
    />

    
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={c5}
      alt="Third slide"
    />

    
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={c6}
      alt="Third slide"
    />

    
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={c7}
      alt="Third slide"
    />

    
  </Carousel.Item>
</Carousel>
  )
}
