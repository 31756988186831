import React, {useRef,useState} from 'react'
import { Form, Button, Card, Alert, Container } from 'react-bootstrap' 
import { useAuth } from '../contexts/authContext';
import {useNavigate, Link} from 'react-router-dom'

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useNavigate()

  async function handleSubmit(e){
      e.preventDefault();
      

      try{
          setError('')
          setLoading(true)
          await login(emailRef.current.value, passwordRef.current.value)
          history('/')
      } catch {
          setError('Failed to sign in')
      }
      setLoading(false)
      
  }

  return (
    <>
    <Container className="d-flex align-items-center justify-content-center"
    style={{minHeight:"100vh"}}>
      <div className="w-100" style={{maxWidth:'400px'}}>
        <Card>
            <Card.Body>
                <h2 className="text-center mb-3">Log In</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" required ref={emailRef}></Form.Control>
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" required ref={passwordRef}></Form.Control>
                    </Form.Group>
                    <Button disabled={loading} type="submit" className="w-100 mt-3">Log In</Button>
                </Form>
                <div className="w-100 text-center mt-3">
                    <Link to="/forgot-password">Forgot Password?</Link>
                </div>
            </Card.Body>

        </Card>
        </div>
        
      </Container>
        
    </>
  )
}
