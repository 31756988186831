import React, {useContext, useState, useEffect} from 'react'
import {auth, projectFirestore, projectStorage, timestamp} from '../firebase'

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState(0)
    const [url, setUrl] = useState(null)
    const [error, setError] = useState(null)
    const [docs, setDocs] = useState([])

    function signup(email, password){
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(email,password){
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email){
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    function useStorage(file, title){
        useEffect(() => {
            console.log(file)
          const storageRef = projectStorage.ref(file.name)
          const collectionRef = projectFirestore.collection('videos')

          storageRef.put(file).on('state_changed', (snapshot) => {
              let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              setProgress(percentage)

          },(err) => {
              setError(err)
          },async () => {
              const url = await storageRef.getDownloadURL()
              const createdAt = timestamp()
              collectionRef.add({url:url, title:title, createdAt})
              setUrl(url)
          })
        }, [file])

        return {progress, url, error}
        
    }

    function useFirestore(collection){

        useEffect(() => {
            projectFirestore.collection(collection)
            .onSnapshot((snap) => {

            })
        },[collection])
        return {docs};
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
            
        })

        return unsubscribe
    }, [])
    
    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        useStorage
    }
  return (
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
  )
}
