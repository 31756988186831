import React from 'react'
import { Feature } from '../../components'
import './blog.css'

export default function Blog() {
  return (
    <div className='custom'>
    <div className="gpt3__whatgpt3 section__margin" id="cooperation" >
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">Cooperation Horizon</h1>
      </div>
      
      <div className="gpt3__whatgpt3-container">
        
        <Feature title="" text="Establishing Memorandum of Understanding (MoU)  with  universities and other research/industrial organizations across the globe for carrying out joint research activities in the broad domains of cutting edge technologies." />
        <Feature title="" text="Endowing Memorandum of Understanding (MoU)  with  government (public) and private sectors across the globe for providing cost effective and viable research solutions." />
      </div>
      <Feature title="" text="" />
      <div>
        <p style = {{color:"#ffff"}}>
        <h3>List of Cooperation with other Organizations:</h3> <br/> 
    1. INJE UNIVERSITY Technology Holdings Co. LTD, SOUTH KOREA <br/> 
    2. INJE UNIVERSITY Industry Academic Cooperation Foundation, SOUTH KOREA 
    3. BIRLA INSTITUTE OF TECHNOLOGY MESRA, DEOGHAR CAMPUS, DEOGHAR, JHARKHAND, INDIA <br/> 
    4. VY INSTITUTE OF MEDICAL SCIENCES, RAIPUR, CHHATTISGARH, INDIA<br/> 
    5. NEW HORIZON COLLEGE OF ENGINEERING, BANGALORE, KARNATAKA, INDIA.<br/> 
    6. PBR VISVODAYA INSTITUTE OF TECHNOLGY AND SCIENCE, KAVALI, NELLORE DT, AP. INDIA <br/> 
        </p>
      </div>
    </div>
    
    <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <h3>A web link for study materials and  open source datasets for AI and ML research project will be provided.</h3>
    </div>
    <div className="gpt3__cta-btn">
      <a href="/login"><button type="button">Get Started</button></a>
    </div>
  </div>
    </div>
  )
}
