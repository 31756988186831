import React from 'react'
import Feature from '../../components/feature/Feature';
import './possibility.css';

const featuresData = [
  {
    title: 'AI/ML  for Healthcare Applications',
    text: '',
  },
  {
    title: 'AI/ML for Earth Observation(EO) data and Climate Change Applications',
    text: '',
  },
  {
    title: 'AI/ML  for Cybersecurity  and IoT Applications',
    text: '',
  },
  {
    title: 'AI/ML  for  Waste Management ',
    text: '',
  },
  
];

const featuresData2 = [
  {
    title: 'AI/ML for 5G and B5G architectures for Industry 4.0',
    text: '',
  },
  {
    title: 'AI/ML for  3D IC  Reliability Applications',
    text: '',
  },
  {
    title: 'AI/ML for Chip Design',
    text: '',
  },
  {
    title: 'AI/ML for   Semiconductor  Companies',
    text: '',
  },
  
];

const featuresData3 = [
  {
    title: 'AI/ML  Mechanical Manufacturing Applications',
    text: '',
  },
  {
    title: 'FPGA  Implementation of Neural Networks  Models',
    text: '',
  },
  {
    title: 'Product design and development',
    text: '',
  },
  {
    title: 'Chip Fabrication',
    text: '',
  },
  {
    title: 'AI/ML for Natural Language Processing (NLP)'
  },
];


export default function Possibility() {
  return (
    <div className=" gpt3__featuresp section__padding" id="research">
      <div className="gpt3__features-headingp">
        <h1 className="gradient__text">Research Areas</h1>
      </div>
      <div className="gpt3__features-container">
        <div className="gpt3__features-containerp">
          {featuresData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
        <div className="gpt3__features-containerp">
          {featuresData2.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
        <div className="gpt3__features-containerp">
          {featuresData3.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
        </div>
      </div>
  )
}
