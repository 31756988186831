import React,{useState} from 'react'
import {Card, Button, Alert, Container} from 'react-bootstrap'
import {useAuth} from '../contexts/authContext'
import {Link, useNavigate} from 'react-router-dom'
import useFirestore from '../hooks/useFirestore'
import Navbarcomp from './Navbarcomp'
import Navbaruser from './Navbaruser'

export default function Dashboard() {
    const [error, setError] = useState('')
    const {currentUser, logout} = useAuth()
    const history = useNavigate()

    async function handleLogout(){
        setError('')
        try{
            await logout()
            history('/')
        } catch{
            setError('Failed to log out')
        }
    }

    const {docs} = useFirestore('videos')
    // console.log(docs)
    console.log(currentUser.email)

    
    

    if(currentUser.email==='admin@admin.com') {
        if(docs.length===0){return(<><Navbarcomp /> <p>No videos uploaded yet</p></>)} else {return (
        <>
            
                <Navbarcomp />
                    {docs && docs.map(doc => (
                        <Container className='d-inline-flex mt-5 col-3 col-md-2 col-sm-12' key={doc.id}>
                        <Card >
                            <Card.Body>
                                <Card.Title>{doc.title}</Card.Title>
                                <Button variant="primary"><Link  to={`/videos/${doc.id}`} style={{color:'white', textDecoration:'none'}}>See Video</Link></Button>
                            </Card.Body>
                            
                        </Card>
                        </Container>
                        
                    ))}
                
            
            
        </>
    )}} else {
        {return (<>
            <Navbaruser />
            {docs && docs.map(doc => (
                        <Container className='d-inline-flex mt-5 col-3 col-md-2 col-sm-12' key={doc.id}>
                        <Card >
                            <Card.Body>
                                <Card.Title>{doc.title}</Card.Title>
                                <Button variant="primary"><Link  to={`/videos/${doc.id}`} style={{color:'white', textDecoration:'none'}}>See Video</Link></Button>
                            </Card.Body>
                            
                        </Card>
                        </Container>
                        
                    ))}
        </>)}
    }
}
