import React from 'react'
import { useParams } from 'react-router-dom'
import {useAuth} from '../contexts/authContext'
import { Player } from 'video-react';
import { collection, query, where, getDocs } from "firebase/firestore";
import useFirestore from '../hooks/useFirestore'
import Navbarcomp from './Navbarcomp';
import { Container } from 'react-bootstrap';
import Navbaruser from './Navbaruser';
import "../../node_modules/video-react/dist/video-react.css";

export default function Detail() {
    
    const {docs} = useFirestore('videos')
    const {currentUser, logout} = useAuth()

    const { id } = useParams()
    const doc = docs.find(doc => doc.id === id)
    if(currentUser.email==='admin@admin.com'){
        if(doc)
        return (
        <>
        <Navbarcomp/>
        {/* <Container style={{width:'700px', paddingTop:'40px'}}> */}
        <Player
            config={{ 
            file: { 
              attributes: {
                onContextMenu: e => e.preventDefault()
              } 
            } 
          }}
            playsInline
            src={doc.url}
            width={700}
            />
        {/* </Container> */}
        
    </>
    
    )
    else return <div>Loading</div>
    } else {
        if(doc)
    return (
        <>
        <Navbaruser/>
        <Container style={{width:'700px', paddingTop:'40px'}}>
        <Player
            
            playsInline
            src={doc.url}
            />
        </Container>
        
    </>
    
    )
    else return <div>Loading</div>
    }
}
