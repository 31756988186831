import React from 'react'
import { Feature } from '../components'
import { Footer } from '../containers'
import Navbar from './navbar/Navbar'

export default function CourseInstructors() {
  return (
      <div className="gradient__bg">
          <Navbar />
          <div className="gpt3__whatgpt3-heading">
                <h1 style={{color:'#ffff', paddingLeft:'15px'}}>Associated Course Instructors:</h1>
          </div>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>Dr. Shashidar Kulgudi, Associate Professor, CSE Dept. NITK Surathkal</p>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>Dr. Jeny Rajan, Assistant Professor, CSE Dept. NITK Surathkal</p>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>Dr. Basvaraj Talwar, Assistant Professor, CSE Dept. NITK Surathkal</p>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>Dr. Deepu Vijayasenan, Associate Professor, ECE Dept. NITK Surathkal</p>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>Dr. Aparna P, Assistant Professor, ECE Dept. NITK Surathkal </p>
          
        <Footer />
      </div>
      
  )
}
