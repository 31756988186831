import React, { useEffect } from 'react'
// import { useAuth } from '../contexts/authContext';
import useStorage from '../hooks/useStorage';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Progress({file, setFile, title}) {
    // const { useStorage } = useAuth();
    const navigate = useNavigate();
    const { url, progress } = useStorage(file, title);

    useEffect(() => {
        if(url){
            setFile(null)
            navigate('/')
        }
    },[url, setFile])
    return (
        <ProgressBar now = {progress}/>
    )
}
