import React, {useRef,useState} from 'react'
import { Form, Button, Card, Alert, ProgressBar, Container } from 'react-bootstrap' 
import { useAuth } from '../contexts/authContext';
import { Link} from 'react-router-dom'
import Progress from './Progress';
import Navbarcomp from './Navbarcomp'

export default function UploadForm() {
  const titleRef = useRef();
  const { useStorage } = useAuth();
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [file, setFile] = useState(null) 
  const [url, setUrl] = useState(null)
  const [progress, setProgress] = useState(0)


  async function handleSubmit(e){
    //    e.preventDefault();

    //    try{
    //     setError('')
    //     setLoading(true)
    //     await useStorage(file, titleRef.current.value)
    //     setUrl(url)
    //     setProgress(progress)
    //     // useEffect(() => {
    //     //     if(url){
    //     //         setFile(null)
    //     //     }
    //     // },[url, setFile])
    //     history('/')
    //     } catch {
    //         setError('Failed to upload video')
    //     }
    //     setLoading(false)
      
  }

  const changeHandler = (e) => {
      console.log(e)
      let selected = e.target.files[0]

      if(selected){
            setError('')
            setFile(selected)
      } else {
          setFile(null)
          setError('Select a valid file or fill the title field')
      }
  }

  return (
    <>
    <Navbarcomp />
    <Container className="d-flex align-items-center justify-content-center"
    style={{minHeight:"100vh"}}>
      <div className="w-100" style={{maxWidth:'400px'}}>
        <Card>
            <Card.Body>
                <h2 className="text-center mb-3">Upload Video</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
                
                <Form >
                    <Form.Group id="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" required ref={titleRef}></Form.Control>
                    </Form.Group>
                    <Form.Group id="upload">
                        <Form.Label>Upload File</Form.Label>
                        <Form.Control type="file" required onChange={changeHandler}></Form.Control>
                    </Form.Group>
                    
                    <Button hidden={true} disabled={loading} type="submit" className="w-100 mt-3">Upload</Button>
                </Form>
                {file && <Progress className="mt-3" file={file} setFile={setFile} title={titleRef.current.value} />}
            </Card.Body>

        </Card>
        </div>
        
      </Container>
        
    </>
  )
}
