import React from 'react'
import ai from '../../assets/ai.png';
import './header.css';

export default function Header() {
  return (
    <>
      <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className='gradient__text' style={{color:'#ffff'}}>To be  Leaders of  Artificial Intelligence, Machine Learning and other Cutting Edge Technologies.</h1>
      
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
    </>
  )
}
