import React from 'react'
import './article.css';

export default function Article({ imgUrl, date, text, hp }) {
  return (
    <div className="gpt3__blog-container_article">
      <div className="gpt3__blog-container_article-image">
        <img src={imgUrl} alt="blog_image" />
      </div>
      <div className="gpt3__blog-container_article-content">
        <div>
          
          <h3>{text}</h3>
          <a href={hp} style={{textDecoration:'None'}}><p>Home Page</p></a>
          <p>{date}</p>
          
        </div>
      </div>
    </div>
  )
}
